import { createWebHistory, createRouter } from "vue-router";
import { getUserRole } from "../services/authService"; // Importer la fonction de rôle

const routes = [

    //Authentification routes
    {
      path: "/auth-login",
      name: "auth-login",
      component: () => import('@/pages/auth-pages/auth-login.vue'),
    },
    {
      path: "/auth-signup",
      name: "auth-signup",
      component: () => import('@/pages/auth-pages/auth-signup.vue'),
    },
    {
      path: "/auth-signup-success",
      name: "auth-signup-success",
      component: () => import('@/pages/auth-pages/auth-signup-success.vue'),
    },
    {
      path: "/auth-re-password",
      name: "auth-re-password",
      component: () => import('@/pages/auth-pages/auth-re-password.vue'),
    },

    // Home
    {
      path: "/",
      name: "index",
      component: () => import('@/pages/home/index.vue'),
    },
    {
      path: "/page-service-provider",
      name: "page-service-provider",
      component: () => import('@/pages/home/page-service-provider.vue'),
    },
    // {
    //   path: "/provider-detail",
    //   name: "provider-detail",
    //   component: () => import('@/pages/home/provider-detail.vue'),
    // },
    {
      path: "/contact-one",
      name: "contact-one",
      component: () => import('@/pages/home/contact-one.vue'),
    },
    {
      path: "/helpcenter-faqs",
      name: "helpcenter-faqs",
      component: () => import('@/pages/home/helpcenter-faqs.vue'),
    },
    {
      path: "/page-terms",
      name: "page-terms",
      component: () => import('@/pages/home/page-terms.vue'),
    },
    {
      path: "/page-privacy",
      name: "page-privacy",
      component: () => import('@/pages/home/page-privacy.vue'),
    },

    //user account
    {
      path: "/user",
      name: "index-user",
      component: () => import('@/pages/account/index-user.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/chat",
      name: "chat",
      component: () => import('@/pages/account/chat.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import('@/pages/account/profile.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/prestataire/:id",
      name: "prestataire",
      component: () => import('@/pages/account/profile-prestataire.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/user/:id",
      name: "user",
      component: () => import('@/pages/account/consultation-user.vue'),
      meta: { requiresAuth: true, role: 1 },
    },
    {
      path: "/profile-billing",
      name: "profile-billing",
      component: () => import('@/pages/account/profile-billing.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/profile-payment",
      name: "profile-payment",
      component: () => import('@/pages/account/profile-payment.vue'),
      meta: { requiresAuth: true},
    }, 
    {
      path: "/profile-social",
      name: "profile-social",
      component: () => import('@/pages/account/profile-social.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/profile-notification",
      name: "profile-notification",
      component: () => import('@/pages/account/profile-notification.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/profile-setting",
      name: "profile-setting",
      component: () => import('@/pages/account/profile-setting.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/blog",
      name: "blog",
      component: () => import('@/pages/account/blog.vue'),
      meta: { requiresAuth: true},
    },
    {
      path: "/faqs",
      name: "faqs",
      component: () => import('@/pages/account/faqs.vue'),
    },
    {
      path: "/pricing",
      name: "pricing",
      component: () => import('@/pages/account/pricing.vue'),
      meta: { requiresAuth: true},
    },
    
    //admin account
    {
      path: "/admin",
      name: "index-admin",
      component: () => import('@/pages/account/index-admin.vue'),
      meta: { requiresAuth: true, role: 1 },
    },
    
  
  {
    path: "/:catchAll(.*)",
    name: "Error",
    component: () => import('@/pages/special-pages/page-error.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


// Ajouter le garde de navigation
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const role = to.meta.role;

  // Vérifie si la route nécessite une authentification
  if (requiresAuth) {
      const userRole = getUserRole();
      
      if (!userRole) {
          // Redirige vers la page de connexion si l'utilisateur n'est pas connecté
          return next({ name: "auth-login" });
      }

      // Vérifie le rôle de l'utilisateur
      if (role && role !== userRole) {
          // Redirige si l'utilisateur n'a pas le rôle requis
          return next({ name: "Error" });
      }
  }
  next(); // Continue la navigation si toutes les conditions sont respectées
});

export default router;